














import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import axios from 'axios';

import { IPageService } from '@/services/interfaces';
import { lazyInject, SERVICE_IDENTIFIER } from '@/ioc/container';

import Layout from '@/layouts/LayoutDefault.vue';

@Component({
  components: {
    Layout,
  },
})
export default class UserGuideView extends Vue {
  helpTitle: string = '';
  helpDescription: string = '';

  @lazyInject(SERVICE_IDENTIFIER.IPageService)
  pageService!: IPageService;

  async created() {
    const helpPage = await this.pageService.get('help');
    this.helpTitle = helpPage.title;
    this.helpDescription = helpPage.description;
  }
}
